import { FAQ_ROUTE, HOME_ROUTE, NEWS_ROUTE, POLLUTION_ROUTE } from 'src/constants/routes.constants';
import MenuFaq from './menu-items/menu-faq.component';
import MenuHome from './menu-items/menu-home.component';
import MenuNews from './menu-items/menu-news.component';
import MenuPollution from './menu-items/menu-pollution.component';

export const MENU_ITEMS = [
    {
        id: 1,
        pathName: `/${HOME_ROUTE}`,
        name: 'Home',
        svg: MenuHome,
    },
    {
        id: 2,
        pathName: `/${NEWS_ROUTE}`,
        name: 'News',
        svg: MenuNews
    },
    {
        id: 3,
        pathName: `/${POLLUTION_ROUTE}`,
        name: 'Inquinanti',
        svg: MenuPollution
    },
    {
        id: 4,
        pathName: `/${FAQ_ROUTE}`,
        name: 'Faq',
        svg: MenuFaq
    },
];