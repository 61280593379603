import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectUserProfile } from 'src/store/auth/auth.selector';
import { isPollutionMenuItemVisible } from 'src/utils/pollution/pollution.utils';
import { useTheme } from 'styled-components';
import { MENU_ITEMS } from './menu';
import { MenuInnerBox, MenuOuterBox } from './menu.styles';

const Menu = () => {
    const user = useSelector(selectUserProfile);

    const { colors } = useTheme();

    const { pathname } = useLocation();

    const getSvgFillColor = (itemPathName: string) => {
        return pathname.includes(itemPathName)
            ? colors.secondary.darker
            : colors.secondary.lighter;
    };

    const menuItems = MENU_ITEMS.filter(
        (item) =>
            item.pathName !== '/pollution' || isPollutionMenuItemVisible(user)
    );

    return (
        <MenuOuterBox>
            <MenuInnerBox>
                {menuItems.map((item) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Link to={item.pathName} key={item.id}>
                            <motion.div
                                animate={{
                                    scale: pathname.includes(item.pathName)
                                        ? 1.2
                                        : 1,
                                }}
                            >
                                {item.svg({
                                    fill: getSvgFillColor(item.pathName),
                                })}
                            </motion.div>
                        </Link>
                        <span>{item.name}</span>
                    </div>
                ))}
            </MenuInnerBox>
        </MenuOuterBox>
    );
};

export default Menu;
